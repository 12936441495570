import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client'

export class UserTenantMgtSvcClientExt extends UserTenantMgtSvcClient {
  usersEndpoint = '/users'

  /**
   * Get currently active user information.
   *
   * @returns Axios response object
   */
  async getCurrentActiveUser() {
    return this.jarvisWebHttpInstance.get({
      url: `${this.usersEndpoint}/me`
    })
  }

  /**
   * Update user information.
   *
   * @param {string} userId user's resource id
   * @param {Record<string, unknown>} user users information
   * @returns Axios response object
   */
  async updateUserInfo(userId: string, user: Record<string, unknown>) {
    return this.jarvisWebHttpInstance.patch({
      url: `${this.usersEndpoint}/${userId}`,
      data: { ...user }
    })
  }
}
